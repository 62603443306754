/* eslint-disable no-useless-catch */
/* OPERATIONS = REDUX THUNKS

This file defines the public interface of the duck -- what can be dispatched from components
Simple operations are just about forwarding an action creator, ex: simpleQuack
Complex operations involve returning a thunk that dispatches multiple actions in a certain order, ex: complexQuack

*/

import * as actions from './actions';
import store from '../store';
import { checkToken } from '../../utils/token-utils';

const getUserOp = () => async (dispatch) => {
  try {
    const response = await dispatch(actions.getUserInfo());
    const {
      first_name,
      last_name,
      role,
      email,
      is_payment_verified,
      company,
      company_id,
      main_company,
    } = response.value.data;

    dispatch(
      actions.setUser({
        first_name,
        last_name,
        role,
        email,
        is_payment_verified,
        company,
        company_id,
        main_company,
      })
    );

    localStorage.setItem('user_name', first_name);

    return response;
  } catch (error) {
    throw error;
  }
};

const userLoginOp = (data) => async (dispatch) => {
  const response = await dispatch(actions.userLogin(data));
  if (response.value.data.status !== 400) {
    const first_name = response.value.data.data.user.first_name;
    const last_name = response.value.data.data.user.last_name;
    const role = response.value.data.data.user.authorisation;
    const email = response.value.data.data.user.email;
    const is_payment_verified = true;
    const company = response.value.data.data.user.company;
    const company_id = response.value.data.data.user.COMPANY_ID;
    const main_company = response.value.data.data.user.COMPANY_ID[0];

    dispatch(
      actions.setUser({
        first_name,
        last_name,
        role,
        email,
        company,
        is_payment_verified,
        company_id,
        main_company,
      })
    );

    if (company !== '') {
      dispatch(actions.setUserSitemode(false));
    } else {
      dispatch(actions.setUserSitemode(true));
    }

    localStorage.setItem('user_name', first_name);
    return response;
  } else {
    return response;
  }
};

const userLogoutOp = () => async (dispatch) => {
  if (store.getState().tokenReducer.tokens.loginToken !== undefined) {
    const { first_name } = store.getState().userReducer.user;
    localStorage.setItem('user_name', first_name);

    try {
      const response = await dispatch(actions.userLogout());

      return response;
    } catch (error) {
      throw error;
    }
  }
};

const userVerifyConfirmationOp = (data) => async (dispatch) => {
  try {
    const response = await dispatch(actions.userVerifyConfirmation(data));
    return response;
  } catch (error) {
    throw error;
  }
};

export { userLoginOp, userLogoutOp, userVerifyConfirmationOp, getUserOp };
