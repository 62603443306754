import axios from 'axios';

import store from '../../redux/store';
import { userOperations } from '../../redux/user';

const config = {
    baseURL: process.env.REACT_APP_VOX_DEV_BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT,DELETE',
        'Access-Control-Allow-Headers': '',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
    },
};

let axios1 = axios.create(config);

axios1.interceptors.response.use(null, function (error) {
    if (error && (!error.response || error.response.status === 401)) {
        store.dispatch(userOperations.userLogoutOp());
    }
    return Promise.reject(error);
});

/**
 * gives headers with bearer auth and cache-control
 * 
 * @param {*} token 
 * @returns 
 */
export const getAuthNoCacheHeader = (token) => {
    return {
        Authorization: 'Bearer ' + token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
    }
}

export default axios1;
