import React, { useEffect, useState } from 'react'
import { useLocation,useHistory } from 'react-router';
import api from '../../utils/api/base_node_api';
import { useSelector } from 'react-redux';
const Hero = () => {

    const locationPath = useLocation().pathname;
    const[company,setCompany]=useState({})
    const {loginToken}=useSelector((state)=>state.tokenReducer.tokens)
    const {COMPANY_ID}=useSelector((state)=>state.userReducer.user)
    const history=useHistory()
    useEffect(()=>{
        api.defaults.headers['Authorization'] = 'Bearer ' +loginToken;
    api.get(`/company/get_company/${COMPANY_ID}`).then((res) => {
      console.log('res', res.data.data)
      
      setCompany(res.data.data)
    });
    },[COMPANY_ID])
  return (
    
        <main>
        
            <div className="bg-teal-600  flex px-2 py-2 mt-2 ml-3 mr-2 flex-row rounded-xl">
              <div className="flex items-center hover: cursor">
                <h2 className="text-xl text-white">
                {company.title}

                </h2>
                {company.title && (locationPath.includes('/gdpr-eu')&&"GDPR-EU" ||
                    locationPath.includes('/gdpr-uk')&&"GDPR-UK" ||
                    locationPath.includes('/popia')&&"POPIA" ||
                    locationPath.includes('/gdpr-aus')&&"GDPR-AUS" ||
                    locationPath.includes('/ccpa')&&"CCPA") &&<span className="text-xl pl-2 text-slate-200">
                <svg className="w-5 h-5 text-white mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M9 5l7 7-7 7" />
          </svg>
                </span>}
                <span className="text-xl  text-slate-200">
                    {locationPath.includes('/gdpr-eu')&&"GDPR-EU"}
                    {locationPath.includes('/gdpr-uk')&&"GDPR-UK"}
                    {locationPath.includes('/popia')&&"POPIA"}
                    {locationPath.includes('/gdpr-aus')&&"GDPR-AUS"}
                    {locationPath.includes('/ccpa')&&"CCPA"}
                
                </span>
              </div>
              
            </div>
         
        
        </main>
  
  )
}

export default Hero