/* REDUCER(S)

It's a good practice to define your state shape first.
Based on the state shape, multiple reducers might be defined in this file, combined and exported into a single reducer function.

*/

import { combineReducers } from "redux";
import * as types from "./types";

/* State Shape
{
    merchant: {
        name: string,
        email: string,
        address: string,
        address2: string,
        city: string,
        state: string,
        zip: string,
        phone: string,
        website: string,
        merchant_id: string,
        merchant_id_test: string,
        surcharge_active: bool,
        surchx_status: string,
        portal_status: string,
    },
}
*/

const merchantState = {
    name: undefined,
	email: undefined,
	address: undefined,
	address2: undefined,
	city: undefined,
	state: undefined,
	zip: undefined,
	phone: undefined,
	website: undefined,
    merchant_id: undefined,
    merchant_id_test: undefined,
    surcharge_active: true,
    surchx_status: undefined,
    portal_status: undefined,
};

const merchantReducer = ( state = merchantState, action ) => {
    switch( action.type ) {
        case types.SET_SURCHARGE_ACTIVE:
            return {
                ...state,
                surcharge_active: action.payload.data,
            };
        case types.SET_INTERPAYMENTS_STATUS:
            return {
                ...state,
                surchx_status: action.payload.data,
            };
        case types.SET_MERCHANT_STATUS:
            return {
                ...state,
                portal_status: action.payload.data,
            };
        case types.SET_MERCHANT:
            return {
                ...state,
                ...action.payload,
            };
        case types.SET_MERCHANT_ID:
            return {
                ...state,
                merchant_id: action.payload.data,
            };
        case types.SET_MERCHANT_ID_TEST:
            return {
                ...state,
                merchant_id_test: action.payload.data,
            };
        case types.EDIT_MERCHANT:
        case types.GET_MERCHANT:
            return action.payload;
        default: return state;
    }
}

const reducer = combineReducers( {
    merchant: merchantReducer,
} );

export default reducer;