import React from 'react';
import store from './redux/store';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'react-router-redux';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import FullScreenLoader from './components/FullScreenLoader';
// Notifications import
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Other setup
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import { isMobileOnly } from 'react-device-detect';
import 'bootstrap/dist/css/bootstrap.css';
// import './assets/homepage.css';
// import './assets/login.css';
// import './assets/table.css';
// import './assets/vendor.css';
// import './assets/healthCheckPdf.css';
import './index.css';
import Mobile from './Mobile.js';
import Hero from './scenes/hero/Hero.js';

// Redux imports

// Redux setup

let browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, store);

// Components

const loading = () => <FullScreenLoader />;
const MainLayout = React.lazy(() => import('./containers/Layout/index'));
const Registration = React.lazy(() => import('./scenes/Registration/index'));
const SignupConfirmation = React.lazy(() =>
  import('./scenes/SignupConfirmation/index')
);
const Login = React.lazy(() => import('./scenes/Login/index'));
const confirmationPage = React.lazy(() => import('./scenes/confirmationPage'));
const forgotPasswordPage = React.lazy(() =>
  import('./scenes/forgotPasswordPage')
);
const resetPasswordPage = React.lazy(() =>
  import('./scenes/resetPasswordPage')
);
const UnlockAccountPage = React.lazy(() =>
  import('./scenes/UnlockAccountPage/index.js')
);

const Chat = React.lazy(() => import('./scenes/Chat'));

const loggedIn = () => {
  const boolToken = store.getState().tokenReducer.tokens.loginToken;

  if (boolToken === null || boolToken === undefined) {
    return false;
  } else {
    return true;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
};

const LoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn() ? (
          <Redirect
            to={{
              pathname: '/loading',
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

LoginRoute.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
};

const ExemptRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

ExemptRoute.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
};

const ComponentRoutes = () => {
  return (
    <React.Fragment>
      <ToastContainer />
      <BrowserRouter>
        <ErrorBoundary>
          <React.Suspense fallback={loading()}>
            <Switch>
              <LoginRoute exact path='/login' name='Login' component={Login} />
              <LoginRoute exact path='/chat' name='Chat' component={Chat} />
              <LoginRoute
                exact
                path='/signup'
                name='Sign Up'
                component={Registration}
              />
              <LoginRoute
                exact
                path='/confirmation/:token'
                name='Confirmation'
                component={confirmationPage}
              />
              <LoginRoute
                exact
                path='/forgot_password'
                name='Forgot Password'
                component={forgotPasswordPage}
              />
              <ExemptRoute
                exact
                path='/unlock'
                name='Unlock Account'
                component={UnlockAccountPage}
              />
              <LoginRoute
                exact
                path='/password/edit/:token'
                name='Reset Password'
                component={resetPasswordPage}
              />
              <LoginRoute
                exact
                path='/email_confirmation'
                name='Registration Confirmation'
                component={SignupConfirmation}
              />
              <PrivateRoute path='/' name='MainLayout' component={MainLayout} />
            </Switch>
          </React.Suspense>
        </ErrorBoundary>
      </BrowserRouter>
    </React.Fragment>
  );
};

class App extends React.Component {
  render() {
    // return isMobileOnly ? (
    //   <Mobile />
    // ) : (
    //     <Provider store={store} history={history}>
    //     <ComponentRoutes />
    //   </Provider>
    // );

    return (
      <Provider store={store} history={history}>
       
        <ComponentRoutes />
        
      </Provider>
    )
  }
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);
