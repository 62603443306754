import React from 'react';
import ErrorMessage from './ErrorMessage';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: '', errorStack: '' };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        // console.log('error in derivedState: ', error);
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // console.log('error in componentDidCatch: ', error);
        // console.log('componentStack: ', errorInfo.componentStack);
        this.setState({ error, errorStack: errorInfo.componentStack });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <ErrorMessage
                    error={this.state.error}
                    errorStack={this.state.errorStack}
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
