import { combineReducers } from 'redux';
import userReducer from './user/index';
import tokenReducer from './tokens/index';
import merchantReducer from './merchant/index';
import adminReducer from './admin/index';
import profileReducer from './profile/index';
import uiReducer from './ui/index';
import dashboardReducer from './dashboard/index';

import { routerReducer } from 'react-router-redux';

const appReducer = combineReducers({
  userReducer,
  tokenReducer,
  profileReducer,
  merchantReducer,
  adminReducer,
  ui: uiReducer,
  dashboardReducer,

  routing: routerReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'REDUX/USER/USER_LOGOUT_LOADING') {
    state = undefined;
    // sessionStorage.clear();
    // localStorage.clear();
  }

  return appReducer(state, action);
};

export default rootReducer;
