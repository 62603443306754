import React, { Component } from 'react';

import { MobileView } from 'react-device-detect';
const styles = {
    backgroundColor: '#3fa1a1',
    color: 'white',
    fontSize: '20px',
    lineHeight: '2',
    marginTop: 'auto',
    marginBottom: 'auto',
};
class Mobile extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <MobileView style={styles}>
                This Site Cannot be viewed in Mobile. Please use a Desktop
            </MobileView>
        );
    }
}

export default Mobile;
