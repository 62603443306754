/* OPERATIONS = REDUX THUNKS

This file defines the public interface of the duck -- what can be dispatched from components
Simple operations are just about forwarding an action creator, ex: simpleQuack
Complex operations involve returning a thunk that dispatches multiple actions in a certain order, ex: complexQuack

*/

import store from '../store';
import * as actions from './actions';
import api from '../../utils/api/base_node_api';
import { checkToken } from '../../utils/token-utils';
import moment from 'moment';

const getMerchantBearerToken = () => {
    return (dispatch) => {
        api.defaults.headers['Authorization'] =
            'Bearer ' + store.getState().tokenReducer.tokens.loginToken;
        return api
            .post('token', { siteMode: true })
            .then((response) => {
                dispatch(
                    actions.setMerchantBearerToken(response.data.bearerToken)
                );
            })
            .catch((error) => {
                throw error;
            });
    };
};

const getMerchantBearerTokenTest = () => {
    return (dispatch) => {
        api.defaults.headers['Authorization'] =
            'Bearer ' + store.getState().tokenReducer.tokens.loginToken;
        return api
            .post('token', { siteMode: false })
            .then((response) => {
                dispatch(
                    actions.setMerchantBearerTokenTest(
                        response.data.bearerToken
                    )
                );
            })
            .catch((error) => {
                throw error;
            });
    };
};

const createTokenData = (data) => {
    const { category, tokenLabel } = data;
    let dataString = store.getState().merchantReducer.merchant.name;
    let dataToReturn = [];

    // dataToReturn.push(store.getState().merchantReducer.merchant.name);
    if (category !== '') {
        // dataToReturn.push(category);
        dataString += ` - ${category}`;
    }
    if (tokenLabel !== '') {
        // dataToReturn.push(tokenLabel);
        dataString += ` - ${tokenLabel}`;
    }

    dataToReturn.push(dataString);

    return dataToReturn;
};

export { getMerchantBearerToken, getMerchantBearerTokenTest };
