import {
    ADD_EXAM_SUMMARY, CLEAR_EXAM_SUMMARY
} from './types';

const INITIAL_STATE = {};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_EXAM_SUMMARY:
            return { ...state, ...action.payload };
        case CLEAR_EXAM_SUMMARY:
            return INITIAL_STATE;
        default:
            return state;
    }
};


