import React from 'react';
import Loader from 'react-loader-spinner';

const FullScreenLoader = () => {
    return (
        <div className="full-screen__loader">
            <Loader type="Oval" color="#fff" height="150" width="150" />
        </div>
    );
};

export default FullScreenLoader;