/* ACTION TYPE CONSTANTS

You can use any convention you wish here, but the name should remain UPPER_SNAKE_CASE for consistency.

*/

const SET_MERCHANT                  = 'REDUX/MERCHANT/SET_MERCHANT';
const SET_MERCHANT_ID               = 'REDUX/MERCHANT/SET_MERCHANT_ID';
const SET_MERCHANT_ID_TEST          = 'REDUX/MERCHANT/SET_MERCHANT_ID_TEST';
const EDIT_MERCHANT                 = 'REDUX/MERCHANT/EDIT_MERCHANT';
const GET_MERCHANT                  = 'REDUX/MERCHANT/GET_MERCHANT';
const SET_SURCHARGE_ACTIVE          = 'REDUX/MERCHANT/SET_SURCHARGE_ACTIVE';
const SET_INTERPAYMENTS_STATUS             = 'REDUX/MERCHANT/SET_INTERPAYMENTS_STATUS';
const SET_MERCHANT_STATUS           = 'REDUX/MERCHANT/SET_MERCHANT_STATUS';
const GET_MERCHANT_INVOICE          = 'REDUX/MERCHANT/GET_MERCHANT_INVOICE';
const GET_MERCHANT_INVOICE_LIST     = 'REDUX/MERCHANT/GET_MERCHANT_INVOICE_LIST';
const GET_MERCHANT_STATEMENT        = 'REDUX/MERCHANT/GET_MERCHANT_STATEMENT';

export {
    SET_MERCHANT,
    SET_MERCHANT_ID,
    SET_MERCHANT_ID_TEST,
    EDIT_MERCHANT,
    GET_MERCHANT,
    SET_SURCHARGE_ACTIVE,
    SET_INTERPAYMENTS_STATUS,
    SET_MERCHANT_STATUS,
    GET_MERCHANT_INVOICE,
    GET_MERCHANT_INVOICE_LIST,
    GET_MERCHANT_STATEMENT
};