/* ACTION CREATOR FUNCTIONS

Put here the functions that return an action object that can be dispatched
HINT: Always use functions for consistency, don't export plain objects

*/

import * as types from './types';
import store from '../store';
import node_api from '../../utils/api/base_node_api';

const getUserInfo = () => ({
  type: types.GET_USER_INFO,
  payload: node_api.get('user_info'),
});

const userRegisterNew = (data) => ({
  type: types.USER_REGISTER_NEW,
  payload: node_api.post('/user/create', data),
});

const userRegisterComplete = (data) => {
  node_api.defaults.headers['Authorization'] =
    'Bearer ' + store.getState().tokenReducer.tokens.loginToken;
  return {
    type: types.USER_REGISTER_COMPLETE,
    payload: node_api.post('complete_registration', data),
  };
};

const userRegisterSales = (data) => {
  node_api.defaults.headers['Authorization'] =
    'Bearer ' + store.getState().tokenReducer.tokens.loginToken;
  return {
    type: types.USER_REGISTER_SALES,
    payload: node_api.post('register_sales', data),
  };
};

const userLogin = (data) => ({
  type: types.USER_LOGIN,
  payload: node_api.post('/auth/login', data),
});

const userLogout = () => {
  node_api.defaults.headers['Authorization'] =
    'Bearer ' + store.getState().tokenReducer.tokens.loginToken;
  return {
    type: types.USER_LOGOUT,
    payload: node_api.get('/auth/logout'),
  };
};

const setUser = (data) => ({
  type: types.SET_USER,
  payload: { ...data },
});

const setUserSitemode = (data) => ({
  type: types.SET_USER_SITEMODE,
  payload: { data },
});

const setUserMerchantsid = (data) => ({
  type: types.SET_USER_MERCHANTSID,
  payload: { data },
});

const userVerifyConfirmation = (data) => {
  return {
    type: types.USER_VERIFY_CONFIRMATION,
    payload: node_api.post('/auth/verify-confirmation', data),
  };
};

const userVerifyUnlock = (data) => {
  return {
    type: types.USER_VERIFY_UNLOCK,
    payload: node_api.post('unlock_user', data),
  };
};

const userForgotPassword = (data) => {
  return {
    type: types.USER_FORGOT_PASSWORD,
    payload: node_api.post('forgot_password', data),
  };
};

const userResetPassword = (data) => {
  return {
    type: types.USER_RESET_PASSWORD,
    payload: node_api.post('reset_password', data),
  };
};

const userChangePassword = (data) => {
  node_api.defaults.headers['Authorization'] =
    'Bearer ' + store.getState().tokenReducer.tokens.loginToken;

  return {
    type: types.USER_CHANGE_PASSWORD,
    payload: node_api.post('change_password', data),
  };
};

export {
  userRegisterNew,
  userRegisterComplete,
  userRegisterSales,
  userLogin,
  userLogout,
  setUser,
  setUserSitemode,
  setUserMerchantsid,
  userVerifyConfirmation,
  userVerifyUnlock,
  userForgotPassword,
  userResetPassword,
  userChangePassword,
  getUserInfo,
};
