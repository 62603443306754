/* ACTION CREATOR FUNCTIONS

Put here the functions that return an action object that can be dispatched
HINT: Always use functions for consistency, don't export plain objects

*/

import * as types from './types';
import store from '../store';
import api from '../../utils/api/base_node_api';

const setMerchant = (data) => ({
    type: types.SET_MERCHANT,
    payload: { ...data },
});

const setMerchantId = (data) => ({
    type: types.SET_MERCHANT_ID,
    payload: { data },
});

const setMerchantIdTest = (data) => ({
    type: types.SET_MERCHANT_ID_TEST,
    payload: { data },
});

const editMerchant = (data) => {
    api.defaults.headers['Authorization'] =
        'Bearer ' + store.getState().tokenReducer.tokens.loginToken;

    return {
        type: types.EDIT_MERCHANT,
        payload: api.post('edit_merchant', data),
    };
};

const getMerchant = (data) => {
    api.defaults.headers['Authorization'] =
        'Bearer ' + store.getState().tokenReducer.tokens.loginToken;

    return {
        type: types.GET_MERCHANT,
        payload: api.get(`merchant_info?merchants_id=${data.merchants_id}`),
    };
};

const setSurchargeActive = (data) => {
    return {
        type: types.SET_SURCHARGE_ACTIVE,
        payload: { data },
    };
};

const setMerchantStatus = (data) => ({
    type: types.SET_MERCHANT_STATUS,
    payload: { data },
});

export {
    setMerchant,
    setMerchantId,
    setMerchantIdTest,
    editMerchant,
    getMerchant,
    setSurchargeActive,
    setMerchantStatus,
};
