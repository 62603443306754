/* ACTION CREATOR FUNCTIONS

Put here the functions that return an action object that can be dispatched
HINT: Always use functions for consistency, don't export plain objects

*/

import * as types from './types';
import store from '../store';
import api from '../../utils/api/base_node_api';

const adminTransitionMerchant = (data) => {
    api.defaults.headers['Authorization'] =
        'Bearer ' + store.getState().tokenReducer.tokens.loginToken;
    return {
        type: types.ADMIN_TRANSITION_MERCHANT,
        payload: api.post('transition_merchant', {
            merchant_id: data.merchant_id,
            state: data.state,
        }),
    };
};

const adminChangeRiskStatus = (data) => {
    api.defaults.headers['Authorization'] =
        'Bearer ' + store.getState().tokenReducer.tokens.loginToken;
    return {
        type: types.ADMIN_CHANGE_RISK_STATUS,
        payload: api.post('change_merchant_risk_status', {
            merchant_id: data.merchant_id,
            high_risk: data.high_risk,
        }),
    };
};

const adminSetUserRole = (data) => {
    api.defaults.headers['Authorization'] =
        'Bearer ' + store.getState().tokenReducer.tokens.loginToken;
    return {
        type: types.ADMIN_SET_USER_ROLE,
        payload: api.post('change_user_role', {
            e: data.email,
            r: data.role,
        }),
    };
};

const adminAssignUser = (data) => {
    console.log('data: ', data);
    api.defaults.headers['Authorization'] =
        'Bearer ' + store.getState().tokenReducer.tokens.loginToken;
    return {
        type: types.ADMIN_ASSIGN_USER,
        payload: api.post('assign_merchant', {
            e: data.email,
            merchantId: data.merchantId,
        }),
    };
};

const adminGetMerchantPortalIndex = (data) => {
    api.defaults.headers['Authorization'] =
        'Bearer ' + store.getState().tokenReducer.tokens.loginToken;
    return {
        type: types.ADMIN_GET_MERCHANT_PORTAL_INDEX,
        payload: api.get(`/merchants_autoc?prefix=${data}`),
    };
};

export {
    adminTransitionMerchant,
    adminChangeRiskStatus,
    adminAssignUser,
    adminGetMerchantPortalIndex,
    adminSetUserRole,
};
