import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { userOperations } from '../../redux/user/index';

const ErrorMessage = () => {
  const dispatch = useDispatch();

  const [shouldRedirect, setShouldRedirect] = useState(false);

  const handleClick = () => {
    dispatch(userOperations.userLogoutOp());
    setShouldRedirect(true);
    window.location.reload();
  };

  return shouldRedirect ? (
    <Redirect to={'/login'} />
  ) : (
    <div style={{ backgroundColor: '#2fa89f', height: '700px' }}>
      <h5
        style={{
          fontSize: '20px',
          color: 'white',
          textAlign: 'center',
          paddingTop: '10rem',
        }}
      >
        Oops! it looks like something went wrong.
        <p>
          Please{' '}
          <span
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={handleClick}
          >
            click here
          </span>{' '}
          to log out and try again. If the error persists, please contact
          support.
        </p>
      </h5>
    </div>
  );
};

export default ErrorMessage;
